<template>
  <div class="">

    <div 
            class="pb-1"
            style="    padding: 0px 2% 0px 1.5%;"
          >
            <div
              class=""
              style="
                border-radius: 12px;
                border: 1px solid #ededed;
                background: #fff;
                margin-bottom: 70px;
              "
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style="padding: 8px 0px; border-bottom: 1px solid #ededed"
              >
                <span
                  style="
                    color: #70798d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  "
                  >{{
                    selectedLang.langText
                  }}
                  Translation Settings</span
                >
              </div>
              <div
                class="d-flex justify-content-center align-items-center"
                style="padding: 8px 0px; border-bottom: 1px solid #ededed"
              >
                <div >
                  <span
                    class="midRowContainer"
                    style="text-transform: uppercase"
                  >
                    Product Translation
                  </span>
                </div>

               
              </div>

              <div class="" >
                <div  v-if="isLoadingGetProduct">
                    <div class="TranslationContainer" style="  background: #ffffff;
  padding: 10px 10px;">
    <div class="header-section  d-flex justify-content-between ">
      <div
        class=" bg-preload "
        style="height: 50px; width:45%;  border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      <div
        class=" bg-preload  "
        style="height: 50px; width:45%;  border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      
    </div>
    
  </div>
                </div>
                <div
                  class="row px-3 my-3"
                
                  v-else
                >
               
                <div class="col-md-6 col-12"> 
                    <label for="langCode" class="m-0"  style="
                    color: #70798d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  " >Product Base URL</label>
                    <div
                      style="
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #ededed;
                        background: #fff;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                      "
                    >
                      <input
                        type="text"
                        id="langCode"
                        v-model="baseUrl"
                        placeholder="Product Base URL"
                        class="w-100 converterTextInput"
                      />
                    </div>
                <i class="text-danger" style="font-size:14px;" v-if="isSubmit&&!validateUrl">Invalid Product Base URL </i>
                </div>
               
                <div class="col-md-6 col-12"> 
                    <label for="baseurl" class="m-0"  style="
                    color: #70798d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  " > Language Code</label>
                  <div class="">
                    
                   <div
                   class="d-flex gap"
                      style="
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #ededed;
                        background: #fff;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                      "
                    >
                    <span class="d-flex align-items-center" style="
                    color: #70798d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  ">/</span>
                      <input
                        type="text"
                        id="baseurl"
                        v-model="languageCode"
                        placeholder="Product Base URL"
                        class="w-100 converterTextInput"
                      />
                    </div>
                  </div>
                   
                <i class="text-danger" style="font-size:14px;" v-if="isSubmit&&!languageCode.replace('/','')">Invalid Language Code</i>
                </div>
                <div class="col-12 my-2" v-show="baseUrl&&languageCode.replace('/','')">
                <label  class="m-0"  style="
                    color: #70798d;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  " > {{ baseUrl }}/{{ languageCode.replace('/','') }}/YourProductID</label>
                </div>
                </div>
                
              </div>
            </div>
        
          </div>
          <div
         
        class="d-flex justify-content-end"
        v-if="!isLoadingGetProduct"
        style="
          padding: 12px 24px;
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
          position: fixed;
          bottom: 0px;
          width: calc(100% - 20rem);
        "
      >
        <button class="btn ButtonHover px-3 py-2 quizell-btn"   @click="saveLanguage()"
        @keypress.enter="saveLanguage()" >
        <span v-if="!isLoading">       <svg
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
           
          >
            <path
              d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z"
              fill="white"
            />
          </svg>
          <span>Save Changes</span></span>
          <span v-else> <b-spinner small ></b-spinner> </span>
   
        </button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
    props:{
        selectedLang:Object,
        getEngLang:Object,
        
   components:{
       
    },
    },
    watch:{
    selectedLang(newValue){
      this.getProductTranslation();
      this.languageCode=newValue.lang;

    }
  },
    data(){
        return {
            baseUrl:'',
            languageCode:'',
            isSubmit:false,
            isLoading:false,
            isLoadingGetProduct:false,
        }
    },
    computed:{
    validateUrl() {
    // eslint-disable-next-line no-useless-escape
    const urlRegex = /^(https?:\/\/)?((([\w-]+\.)+[\w-]{2,})|localhost)(:[0-9]{1,5})?(\/[\w\-._~:?#\[\]@!$&'()*+,;=]*)?$/i;
    return urlRegex.test(this.baseUrl);
},
    },
    methods:{
        async getProductTranslation(){
            try{
                this.isLoadingGetProduct=true;
           let response =  await axios.get('/quiz/translation/product-base-url',{params:{language_id:this.selectedLang.id,quizId:this.selectedLang.quiz_id}});
           this.baseUrl=response.data.data.product_base_url;
           if(response.data.data.lang_code)
           this.languageCode=response.data.data.lang_code.replace('/','');
            }
            catch(e){
                this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
                throw e
            }
            finally{
                this.isLoadingGetProduct=false;
            }
        },
        async saveLanguage(){
            this.isSubmit=true;
            this.isLoading=true;
           

            if(!this.validateUrl)
        {
            this.isLoading=false;
            return false
        }
        const pattern = /https:\/\//;

if (!pattern.test(this.baseUrl)) {
this.baseUrl = 'https://'+this.baseUrl
}
            try{
                let response =  await axios.post('/quiz/translation/product-base-url',{product_base_url:this.baseUrl,language_id:this.selectedLang.id,quizId:this.selectedLang.quiz_id,lang_code:`/${this.languageCode}`});
                if(response.status==200)
            {
                this.$toasted.show("Base URL updated successfully.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
            }
            }
            catch(e){
                  this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
                throw e
            }
            finally{
                this.isLoading=false;
            }
        }
    },
    mounted(){
        this.getProductTranslation()
    }
}
</script>

<style scoped>
.converterTextInput,
.converterTextInput:focus {
  background: transparent;
  outline: none;
  border: 0;
  color: #667085;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.quizell-btn, .quizell-btn:hover {
    background: #4d1b7e;
    color: #fff;
    border-radius: 6px;
    min-width: 119px;
    font-size: 14px;
}
</style>