<template>
  <div  style="" class="d-flex align-items-center">
    <div
      style="border: 1px dashed #cccbcd"
      class="d-flex flex-column align-items-center justify-content-center w-100 py-5 emptyJumpLogicContainer"
    >
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4"
          y="4"
          width="56"
          height="56"
          rx="28"
          fill="#EADDF6"
        />
        <rect
          x="4"
          y="4"
          width="56"
          height="56"
          rx="28"
          stroke="#F4EFF9"
          stroke-width="8"
        />
        <path
          d="M40.75 29C40.75 28.5858 40.4142 28.25 40 28.25C39.5858 28.25 39.25 28.5858 39.25 29H40.75ZM40 35V35.75C40.4142 35.75 40.75 35.4142 40.75 35H40ZM34 34.25C33.5858 34.25 33.25 34.5858 33.25 35C33.25 35.4142 33.5858 35.75 34 35.75V34.25ZM22.1016 35.0152C21.9323 35.3933 22.1015 35.837 22.4795 36.0063C22.8576 36.1756 23.3013 36.0063 23.4706 35.6283L22.1016 35.0152ZM26.6461 31.1234L26.2833 30.467L26.6461 31.1234ZM32.2547 30.0878L32.1502 30.8305L32.2547 30.0878ZM39.25 29V35H40.75V29H39.25ZM40 34.25H34V35.75H40V34.25ZM23.4706 35.6283C24.2016 33.9959 25.4434 32.6452 27.009 31.7798L26.2833 30.467C24.4332 31.4897 22.9655 33.086 22.1016 35.0152L23.4706 35.6283ZM27.009 31.7798C28.5745 30.9145 30.3788 30.5813 32.1502 30.8305L32.3592 29.3451C30.2658 29.0506 28.1334 29.4444 26.2833 30.467L27.009 31.7798ZM32.1502 30.8305C35.1278 31.2494 37.0793 33.3867 39.4991 35.5582L40.5009 34.4418C38.2658 32.4361 35.9065 29.8442 32.3592 29.3451L32.1502 30.8305Z"
          fill="#4D1B7E"
        />
      </svg>

      <p
        style="
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
        "
      >
     {{ title }}
      </p>
      <p
        class="text-center"
        style="color: #667085; font-weight: 400; font-size: 14px"
      >
     {{ description }}
      </p>

      <Button class="px-5" @click="$emit('click')" :isLoading="isLoading">
        {{ ButtonText }}</Button
      >
    </div>
  </div>
</template>

<script>

import Button from '../../../Dashboard/Layout/Button.vue';
export default {
   props:{
    title:{
      type:String,
      default:'Translate Your Quiz'
    },
    description:{
      type:String,
      default:'Quickly translate your quiz into several languages to connect with a larger, international audience.'
    },
    ButtonText:{
      type:String,
      default:'Translate Your Quiz'
    },
    isLoading:{
      type:Boolean
    }
   },
components:{
    Button
}
}
</script>

<style scoped>
.emptyJumpLogicContainer {
  background-image: url("https://images.quizell.com/website/jumpLogicBG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
</style>